import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import heroImageTextStyle from '../styles/heroImageText.scss';

const HeroImageText = ({ style, children }) => {
    return (
        <div className='menu-section' css={[css(heroImageTextStyle), css(style)]}>
            {children}
        </div>
    )
}

const HeroImageTextStyled = (props) => (
    <HeroImageText {...props} style={props.style} />
);

HeroImageText.propTypes = {
    children: PropTypes.node,
    style: PropTypes.string
};

HeroImageText.defaultProps = {
    children: null,
    style: ''
};

HeroImageTextStyled.propTypes = {
    children: PropTypes.node,
    style: PropTypes.string
};

HeroImageTextStyled.defaultProps = {
    children: null,
    style: ''
};

export default HeroImageTextStyled;
