import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const Sidebar = ({ data, style }) => {
    return (
        <aside className="sidebar" css={css(style)}>
            <h4>Quick Access</h4>
            <ul>
                {
                    data.map((link, i) => <li key={link.title}><a href={`#${link.link}`}>{link.title}</a></li>)
                }
            </ul>
        </aside>
    )
}

const SidebarStyled = (props) => (
    <Sidebar {...props} style={props.style} />
);

Sidebar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.string
};

Sidebar.defaultProps = {
    data: [],
    style: ''
};

SidebarStyled.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.string
};

SidebarStyled.defaultProps = {
    data: [],
    style: ''
};

export default SidebarStyled;
