import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import heroImageStyle from '../styles/heroImage.scss';

const HeroImage = ({ image, style, children }) => {
  const imageStyle = `.carousel-container {background: url(${image})};`
  return (
    <div css={[css(imageStyle), css(heroImageStyle), css(style)]} >
      <div className='carousel-container'>
        <div className='bg'></div>
        {children}
      </div>
    </div>
  )
};

const HeroImageStyled = (props) => (
  <HeroImage {...props} style={props.style} />
);

HeroImage.propTypes = {
  style: PropTypes.string,
  siteTitle: PropTypes.string,
  children: PropTypes.node
};

HeroImage.defaultProps = {
  style: '',
  image: '',
  children: null
};

HeroImageStyled.propTypes = {
  style: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node
};

HeroImageStyled.defaultProps = {
  style: '',
  image: '',
  children: null
};

export default (HeroImageStyled);