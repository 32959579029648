import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useBreakpoint, withBreakpoints } from 'gatsby-plugin-breakpoints';
import Layout from '../components/Layout';
import BreadCrumb from '../components/Breadcrumb';
import Sidebar from '../components/Sidebar';
import HeroImage from '../components/HeroImage';
import HeroImageText
  from '../components/HeroImageText';
import Seo from '../components/Seo';
import contentPageStyle from '../styles/contentPage.scss';

const ContentPage = ({ data, style }) => {
  const breakpoints = useBreakpoint();
  const [titleList, setTitleList] = useState([]);
  const {
    allWpPage: {
      nodes: [
        {
          content,
          slug = '',
          newPagesFields = {},
          title = '',
        } = {}
      ] = []
    } = {},
  } = data;
  
  useEffect(() => {
    if (document.querySelector('.aside-page')) {
      const titleList = Array.from(document.querySelectorAll('link-item')).map(function (item) {
        return { title: item.innerText, link: item.id }
      });
      setTitleList(titleList)
    }
  }, []);
  
  const image = !breakpoints.sm ? (newPagesFields?.headerBackgroundImage?.sourceUrl || '') : (newPagesFields?.headerBackgroundImageMobile?.sourceUrl || '');
  return (
    <Layout style={style}>
      <Seo title={title} />
      <div>
        {
          !breakpoints.sm ? (
            image ? (
              <HeroImage image={image}  >
                <div className='heroimage-text-breadcrumb-container'>
                  <div className='breadcrumb-description'>
                    <div className='breadcrumb-description-text'>
                      {title && <h1>{title}</h1>}
                      {slug && <BreadCrumb url={slug} title={title} />}
                    </div>
                  </div>
                </div>
              </HeroImage>
            ) : (
              <div className="breadcrumb-description-text-only">
                {title && <h1>{title}</h1>}
                <BreadCrumb url={slug} title={title} />
              </div>
            )
          ) : (
            <>
              {
                image && <HeroImage image={image} ></HeroImage>
              }
              <HeroImageText>
                <div className='carousel-description'>
                  {title && <h1>{title}</h1>}
                  {slug && <BreadCrumb url={slug} title={title} />}
                </div>
              </HeroImageText>
            </>
          )
        }
        {
          content && (
            <div className='page-main'>
              <div>
	      <div className="content">
    	      	<section className="content-box" dangerouslySetInnerHTML={{ __html: content }} />
	      </div>
	      </div>
              {titleList.length > 0 && <Sidebar data={titleList} />}
            </div>
          )
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($uri: String!) {
    allWpPage(filter: {uri: {eq: $uri}}) {
        nodes {
          id
          title
          slug
          content
          newPagesFields {
            headerBackgroundImage {
              sourceUrl
            }
            headerBackgroundImageMobile {
              sourceUrl
            }
          }
        }
      }
  }
`

const ContentPageStyled = (props) => (
  <ContentPage {...props} style={contentPageStyle} />
);

ContentPage.propTypes = {
  data: PropTypes.shape({}),
  style: PropTypes.string
};

ContentPage.defaultProps = {
  data: {},
  style: ''
};

ContentPageStyled.propTypes = {
  data: PropTypes.shape({})
};

ContentPageStyled.defaultProps = {
  data: {}
};

export default withBreakpoints(ContentPageStyled);
