import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import breadcrumbStyle from '../styles/breadcrumb.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { getMenuHeirarchy } from '../utils';
import { removeWPLink } from '../utils'
import { useIntl, IntlProvider } from "gatsby-plugin-intl"

const BreadCrumb = ({ url, style, title, directPath }) => {
    const { formatMessage } = useIntl();
    const updatedData = useStaticQuery(graphql`
        query menu {
        allWpMenu {
            nodes {
            menuItems {
                nodes {
                url
                id
                label
                parentId
                }
            }
            id
            name
            }
        }
        }
    `);

    let data;
    if (updatedData) {
        const {
            allWpMenu: {
                nodes
            } = {}
        } = updatedData;

        const updated = getMenuHeirarchy(nodes);
        const headerMenu = updated.filter(menu => menu.name === 'Header Menu');
        const [
            {
                menuItems: [
                    {
                        children = []
                    } = {}
                ] = []
            } = {}
        ] = updated.filter(menu => menu.name === 'Footer Menu');
        data = [...headerMenu[0].menuItems, ...children];
    }
    let currentCrumbs;
    const getBreadCrumbs = (url = '', items = [], crumbs = [{ label: formatMessage({ id: 'breadcrumbHome' }), url: '/' }]) => {
        if (!items.length) {
            return crumbs;
        }
        for (const item of items) {
            currentCrumbs = [...crumbs, { label: item.label, url: removeWPLink(item.url) }];
            if (item.url.includes(removeWPLink(url)) && item.label !== '/') {
                return currentCrumbs;
            }
            currentCrumbs = getBreadCrumbs(url, item.children, currentCrumbs);
            const [last] = [...currentCrumbs].reverse();
            if (last.url.includes(removeWPLink(url)) && item.label !== '/') {
                return currentCrumbs;
            }
        }
        if (crumbs.length === 1) {
            crumbs = [...crumbs, { label: title, url: removeWPLink(url) }]
        }
        return crumbs;
    }
    const breadCrumbs = directPath ? [{ label: formatMessage({ id: 'breadcrumbHome' }), url: '/' }, ...directPath] : getBreadCrumbs(url, data);

    return (
        <IntlProvider locale="en">
            <div className='breadcrumb' css={[css(breadcrumbStyle), css(style)]} >
                <ul>
                    {
                        breadCrumbs && breadCrumbs.map((breadcrumb, i) => (
                            (breadcrumb.url && i !== breadCrumbs.length - 1) ? (<li key={i}><a href={removeWPLink(breadcrumb.url)}>{breadcrumb.label}</a></li>) : (<li key={i}>{breadcrumb.label}</li>)
                        ))
                    }
                </ul>
            </div>

        </IntlProvider>
    )
}

const BreadCrumbStyled = (props) => (
    <BreadCrumb {...props} style={props.style} />
);

BreadCrumb.propTypes = {
    data: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.string
};

BreadCrumb.defaultProps = {
    url: '',
    title: '',
    style: ''
};

BreadCrumbStyled.propTypes = {
    data: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.string
};

BreadCrumbStyled.defaultProps = {
    url: '',
    title: '',
    style: ''
};

export default BreadCrumbStyled;
